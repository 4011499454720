import React from 'react';
import { Spinner } from 'cj-common-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isEmpty, omit } from '@turbopay/ts-helpers/object-utils';
import BaseEditForm from '../common-components/BaseEditForm';
import FormFields from './FormFields';
import commonPropTypes from '../../common/common-prop-types';
import ConfigurationDataApi from '../../api/ConfigurationData';
import MerchantLinks from './MerchantLinks';
import { OPERATOR_ACCESS_LEVEL, ROUTE_KEYS, ROUTES } from '../../common/constants';
import { revertMerchantDataTransformation } from './merchantDataUtils/revertMerchantDataTransformation';
import { ModalCopyMerchant } from './ModalCopyMerchant';
import { ModalError } from '../common-components/ModalWindow';
import uiTexts from '../../resources/uiTexts.json';
import { getConfigSection, reconcile } from '../../common/utils';
import { RequiredFieldsTip } from '../common-components/RequiredFieldsTip';
import { BlockNavigation } from '../common-components/BlockNavigation';
import { diff } from 'deep-diff';
class MerchantEditForm extends React.PureComponent {
  static propTypes = {
    routeProps: PropTypes.shape({
      location: commonPropTypes.router.location,
      history: commonPropTypes.router.history,
      match: commonPropTypes.router.match,
    }),
    authToken: commonPropTypes.authToken,
    merchantData: commonPropTypes.editForm.data,
    isFormEditable: PropTypes.bool.isRequired,
    lockUnlockMerchant: PropTypes.func,
    reloadMerchantData: PropTypes.func,
  };

  constructor(props) {
    super(props);
    const { authToken } = this.props;
    this.handleGoBack = this.handleGoBack.bind(this);
    this.modifyMerchant = this.modifyMerchant.bind(this);
    this.addMerchant = this.addMerchant.bind(this);
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
    this.state = {
      isLoading: false,
      showWarning: false,
      showMerchantLinks: false,
      errorMessageAcknowledged: true,
      errorMessage: '',
    };
    this.valuesRef = React.createRef();
  }

  compareValues = () => {
    const form = this.valuesRef.current;
    const diffs = (diff(form.initialValues, form.values) || []).filter(change => {
      if (change.kind === 'E') {
        if (reconcile(change.lhs) === reconcile(change.rhs)) {
          return false;
        }
        if (!reconcile(change.lhs) && change.rhs === false) {
          return false;
        }

        if (change.path.includes('businessDomain') && !change.lhs && change.rhs === 'None') {
          return false;
        }
      }

      if (change.kind === 'N') {
        if (!reconcile(change.rhs)) {
          return false;
        }
        if (change.path?.includes('businessDomain') && change.rhs === 'None') {
          return false;
        }
      }
      return true;
    });

    return diffs.length === 0 ? undefined : diffs;
  };

  handleGoBack() {
    this.props.history.replace(ROUTES.merchants);
  }

  goToMerchantPage(merchantId) {
    const route = ROUTES.merchantPage.replace(':merchantId', merchantId);
    this.props.history.replace(route);
  }

  modifyMerchant(merchant) {
    this.setState({ isLoading: true });
    this.configurationDataApi
      .updateMerchant(revertMerchantDataTransformation(merchant))
      .then(() => {
        return this.props.reloadMerchantData();
      })
      .then(() => {
        this.setState({ isLoading: false, successMessage: true });
      })
      .catch(err => {
        this.setState({
          errorMessageAcknowledged: false,
          isLoading: false,
          errorMessage: err.response.data.message,
          successMessage: false,
        });
      });
  }

  addMerchant(merchant) {
    this.setState({ isLoading: true });
    const { authToken } = this.props;
    const { username } = authToken.accessTokenObject;
    const newMerchant = {
      ...merchant,
      channels: [],
      users: [
        {
          id: username,
          accessLevel: OPERATOR_ACCESS_LEVEL.WRITE,
        },
      ],
    };
    this.configurationDataApi
      .addMerchant(revertMerchantDataTransformation(newMerchant))
      .then(() => {
        this.handleGoBack();
      })

      .then(() => {
        this.setState({ isLoading: false });
      })
      .catch(err => {
        this.setState({
          errorMessageAcknowledged: false,
          isLoading: false,
          errorMessage: err.response.data.message,
          successMessage: false,
        });
      });
  }

  isUpdateOperation() {
    const { merchantData } = this.props;
    return !isEmpty(omit(['internalId'], merchantData));
  }

  renderWarningDialog() {
    const { merchantData } = this.props;

    return (
      <>
        <ModalCopyMerchant
          onConfirm={name => {
            this.configurationDataApi.copyMerchant(merchantData.id, { name }).then(merchantData => {
              this.setShowWarning(false);
              this.goToMerchantPage(merchantData.id);
            });
          }}
          onCancel={() => this.setShowWarning(false)}
          onClose={() => this.setShowWarning(false)}
          hasTargetMerchantAccounts={merchantData.hasTargetMerchantAccounts}
        />
      </>
    );
  }

  setShowWarning(value) {
    this.setState({ showWarning: value });
  }

  setShowMerchantLinks(value) {
    this.setState({ showMerchantLinks: value });
  }

  renderErrorDialog() {
    return (
      <ModalError
        errorKey={this.state.errorMessage}
        onConfirm={() => {
          this.setState({
            errorMessageAcknowledged: true,
          });
        }}
      />
    );
  }

  renderSuccessMessage() {
    const text = getConfigSection(uiTexts, 'merchants.form.successMessage');
    return (
      <p className="merchant-changes-success-message" data-testid="success-message">
        {text}
      </p>
    );
  }

  render() {
    const { authToken, isFormEditable } = this.props;
    const { isLoading, showWarning, showMerchantLinks, errorMessageAcknowledged, successMessage } = this.state;
    const { merchantData } = this.props;

    const additionalInitialValues = {
      credentials: {
        apiUsername: '',
        apiPassword: '',
      },
    };

    const overridenInitialValues = {};

    if (!this.isUpdateOperation()) {
      overridenInitialValues.strongAuthentication = true;
      overridenInitialValues.isCheckoutJourney = true;
    }

    return isLoading ? (
      <Spinner center small={false} fullPage />
    ) : (
      <>
        {!errorMessageAcknowledged && this.renderErrorDialog()}
        {showWarning && <>{this.renderWarningDialog()}</>}
        {successMessage && this.renderSuccessMessage()}
        <RequiredFieldsTip />

        <BlockNavigation compareValues={this.compareValues} />

        <BaseEditForm
          data={{ ...merchantData }}
          authToken={authToken}
          textsKey={ROUTE_KEYS.merchants}
          onBack={this.handleGoBack}
          onSaveModifiedItem={this.modifyMerchant}
          onSaveNewItem={this.addMerchant}
          onCancel={this.handleGoBack}
          isVisible
          fieldsComponent={{
            type: FormFields,
          }}
          additionalInitialValues={additionalInitialValues}
          overridenInitialValues={overridenInitialValues}
          validationSchema={FormFields.validationSchema}
          isFormEditable={isFormEditable}
          dataStatusLoadCallback={() => {}}
          isSaveButtonEnable
          additionalButtonComponents={this.renderAdditionalButtonComponents(this.props)}
          valuesRef={this.valuesRef}
        />
        <MerchantLinks merchantData={merchantData} showMerchantLinks={showMerchantLinks} />
      </>
    );
  }

  renderAdditionalButtonComponents(props) {
    const { lockUnlockMerchant, merchantData } = props;

    const { showMerchantLinks } = this.state;
    return [
      {
        text: merchantData.isLocked ? 'Unlock' : 'Lock',
        icon: 'lock',
        key: 'base-lock-unlock-form-button-submit',
        onClick: () => lockUnlockMerchant(props),
        testId: 'lock-button',
      },
      {
        text: 'Copy',
        key: 'base-copy-form-button-submit',
        onClick: () => this.setShowWarning(true),
        testId: 'copy-button',
      },
      {
        text: 'More',
        key: 'base-more-form-button-submit',
        onClick: () => this.setShowMerchantLinks(!showMerchantLinks),
        testId: 'more-button',
      },
    ];
  }
}

export default connect()(withRouter(MerchantEditForm));

import React from 'react';
import BaseEditForm from '../../common-components/BaseEditForm';
import FormFields from './FormFields';
import commonPropTypes from '../../../common/common-prop-types';
import ConfigurationDataApi from '../../../api/ConfigurationData';
import moment from 'moment';
import { ModalError } from '../../common-components/ModalWindow';
import { BlockNavigation } from '../../common-components/BlockNavigation';

export default class EditForm extends React.PureComponent {
  static propTypes = {
    ...commonPropTypes.editForm,
  };

  constructor(props) {
    super(props);

    this.state = {
      notificationRetryExistsError: false,
      isCreationFlow: true,
    };

    this.valuesRef = React.createRef();
    const { authToken } = this.props;

    this.createNotificationRetries = this.createNotificationRetries.bind(this);
    this.configurationDataApi = props.configurationDataApi || new ConfigurationDataApi(authToken.accessToken);
  }

  compareValues = () => {
    const currentValues = this.valuesRef.current.values;

    let hasChanges =
      (currentValues.description || currentValues.creationDateStart || currentValues.creationDateEnd) !== undefined;
    if (currentValues.httpCodes.length > 0) {
      hasChanges = true;
    }
    if (currentValues.isDryRun) {
      hasChanges = true;
    }

    return hasChanges ? [] : undefined;
  };

  render() {
    const { authToken, textsKey, onBack, isFormEditable } = this.props;
    const { isCreationFlow, notificationRetryExistsError } = this.state;

    return (
      <>
        {notificationRetryExistsError && <>{this.renderErrorDialog()}</>}
        <BlockNavigation compareValues={this.compareValues} />
        <BaseEditForm
          valuesRef={this.valuesRef}
          data={{}}
          authToken={authToken}
          textsKey={textsKey}
          onBack={onBack}
          onSaveModifiedItem={() => {}}
          onSaveNewItem={this.createNotificationRetries}
          fieldsComponent={{
            type: FormFields,
          }}
          validationSchema={FormFields.validationSchema}
          isCreationFlow={isCreationFlow}
          isFormEditable={isFormEditable}
        />
      </>
    );
  }
  async createNotificationRetries(notificationRetriesData) {
    const { onBack, merchantId } = this.props;
    const { creationDateEnd, creationDateStart, description, httpCodes, isDryRun } = notificationRetriesData;
    const httpCodeValues = httpCodes.map(item => Number(item.value));

    try {
      await this.configurationDataApi.createNotificationRetries(merchantId, {
        description,
        creationDateStart: moment(creationDateStart).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        creationDateEnd: moment(creationDateEnd).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        httpCodes: httpCodeValues,
        isDryRun: isDryRun || false,
      });
      onBack();
    } catch (error) {
      this.setState({
        notificationRetryExistsError: true,
      });
    }
  }

  renderErrorDialog() {
    return (
      <ModalError
        errorKey="common.generalError"
        onConfirm={() => {
          this.setState({
            notificationRetryExistsError: false,
          });
        }}
      />
    );
  }
}
